import { Box, CircularProgress, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import "../styles/payment-sucess.css";
import axios from "axios";

export function Payment_Success(props){
    const navigate = useNavigate()
    const [data,setData]=useState({})
    const [username,setUsername]=useState('')
    const save_url= props.api_base_url

    useEffect(()=>{

        let user_details = JSON.parse(window.sessionStorage.getItem('userdetails'))
        console.log(user_details)
        if(user_details){
            setUsername(user_details.username)
        }
        else{
            navigate("/login")
        }
        

    },[])

    const handleSubmit = async(e)=>{
        const {data}=await axios.post(save_url+'dnaviz/payment_success/',{
            "username":username,
            })
            setData(data)
            navigate('/payment')            
        }

    const handleKeyPress = (e) => {
            if (e.key === 'Enter') {
              handleSubmit(e); 
            }
          };
  
  

    return (
        <Box className="payment-container">
            <Box className="payment-info">
            <Typography sx={{fontSize:"40px", fontWeight:"500", textTransform:"capitalize", color:"var(--pallate-blue-gray-50, #F8FAFC)"}}>
            Your payment have successfully updated
            </Typography>
            <button style={{ display: "flex", padding: "12px", justifyContent: "center", alignItems: "center", gap: "8px", borderRadius: "4px", background: "var(--pallate-blue-gray-900, #0F172A)",cursor:"pointer" }} onKeyDown={(e)=>handleKeyPress(e)} onClick={(e)=>handleSubmit(e)}>
                            <Typography sx={{ color: "var(--pallate-base-white, #FFF)", fontFamily: "inter", fontSize: "18px", fontStyle: "normal", fontWeight: "600", lineHeight: "28px" }}>Click here to go back to your journey</Typography>
            </button>
            <Typography sx={{ color: "var(--pallate-base-white, #FFF)", fontFamily: "inter", fontSize: "14px", fontStyle: "normal", fontWeight: "500", lineHeight: "28px" }}>Do not refresh the page or select back button</Typography>
            </Box>
        </Box>
    )
}