import { Box, Stack, Typography, useMediaQuery} from "@mui/material";
import "../styles/ResetPassword.css"
import resetPasssword from "../assets/icons/resetpassword.svg"
import arrowLeft from "../assets/create-project/arrow-left.svg"
import lock from "../assets/icons/lock.svg"
import tickIcon from "../assets/icons/check.svg"
import frame from "../assets/landing/D&AViz_Without tagline-Color-Light Lite.svg"
import { useNavigate } from "react-router-dom";
import { useState } from "react"
import axios from "axios"
import eyeSlash from "../assets/icons/eye-slash.svg"
import eye from "../assets/icons/eye.svg"
import forgotbg from "../assets/icons/forgot-bg.svg"

export function ResetPassword(props) {
    const navigate = useNavigate()
    const api_base_url = props.api_base_url
    const [password,setPassword] = useState('')
    const [repassword,setRepassword] = useState('')
    const [message, setMessage] = useState('')
    const queryParameters = new URLSearchParams(window.location.search)
    const small = useMediaQuery("(max-width: 600px)");
    //const username = queryParameters.get('username')
    const [username, setUsername] = useState('')
    const uid = queryParameters.get('uid')
    const [showPassword, setShowPassword] = useState(false);
    const [error,setError] = useState("")
    const tab =  useMediaQuery('(min-width: 600px) and (max-width: 900px)');

    const validate = ()=>{
        let error={
            "password":null,
            "rePassword":null
        }
        // let password_error ,rePassword_error
        if(!password){
            setError("*Password is required")
            setMessage('')
            
        }
        // else{
        //     error.password = null
        //     password_error = false
        // }

        if(!repassword){
            setMessage('')
            setError("Reset password is required")
        }
         else if( password.length<8 || !/[A-Z]/.test(password) || !/[a-z]/.test(password) || !/\d/.test(password) ){
            console.log("in")
            setError("A minimum 8 characters password contains a combination of uppercase and lowercase letter and number are required.")
        }
        else if(repassword && repassword !== password){
            setError("Confirm password is not matching with New password.")
            setMessage('')
        }
        else if (small && repassword && repassword===password){
            navigate('/update-password')           
        }

        else{
            setPassword('')
            setRepassword('')
            setMessage('Your password has been successfully reset. You can now log in with your new password.')
            setError('')
            return true
        }
        // setError(error)
        // if(password_error === false && rePassword_error === false){
        //     return true
        // }
        // else{
        //     return false
        // }
        
    }
    const handleSubmit = (event)=>{
        event.preventDefault()
        if(validate()){
            
            resetPassword()
        }
        
        }
    const resetPassword=async()=>{
        // console.log(username)

        const {data}=await axios.post(api_base_url+'dnaviz/payment_info/',{
            "unique_id":uid,
        })
        setUsername(data.username)
        console.log(data.username)
       const {response} =await axios.post( api_base_url + 'account/reset_password/',{
        username:data.username,
        password:password
       })
       

    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
          handleSubmit(e); 
        }
      };
    const handlePasswordHide = (event) =>{
        event.preventDefault()
        setShowPassword(!showPassword)
    }
    return (
        <Box className="resetPasssword" sx={small&&{bgcolor:"#334155"}}>
            <Box sx={{ display: "flex", flexDirection: "column", width: "100%",mb:{xs:"30px",md:0} }}>
                {!small && <Box className="button-container">
                    <button className="back-button" onClick={() => { navigate(-1) }}> <img src={arrowLeft} className="arrow" alt="arrow-icon" /></button>
                </Box>}
                <Box className="Rectangleframe-resetpassword" sx={small&&{justifyContent:"center",backgroundImage:`url(${forgotbg})`,backgroundPosition:"bottom",backgroundRepeat:"no-repeat",backgroundSize:"cover"}}>
                    <Box sx={{display:"flex", alignItems:"center"}}>
                        <Typography sx={{ color: small ? "#F8FAFC" : "var(--pallate-gray-300, #D1D5DB)", marginBottom: small?"16px":"30px", width: small ? "320px" : "477px", textAlign: "center", fontFamily: "inter", fontSize: small ? "20px" : "48px", fontStyle: "normal", fontWeight: "400", lineHeight: small ? "28px" : "57px" }}>
                            <span style={{ display: small ? "block" : "unset", textAlign:"center", alignItems:"center", color: "var(--pallate-blue-gray-50, #F8FAFC)", marginRight: "20px", fontFamily: "inter", fontWeight: small?"700":"800" }}>
                            Reset Your Password
                            </span>
                            and regain access in just a few clicks
                        </Typography>
                    </Box>
                    <img src={resetPasssword} style={{ width: "100%", maxWidth:small? "199.635px": "604px", height:small?"189.49px": "375.51px", flexShrink: "0", marginBottom:"46px" }} alt="reset-password" />
                </Box>
            </Box>
            <Box  sx={{ display: "flex", flexDirection: "column",  width: "100%",height:small? "fit-content":"100vh"}}>
            <Box sx={{ width: "100%", display: "flex", alignItems: "flex-start", justifyContent: "flex-end", marginRight: "10px" }}>
                    {!small &&
                    <img src={frame} style={{ width: "82px", height: "30px", flexShrink: "0", marginTop: "36px", marginRight: "32px",cursor:"pointer" }} alt="frame" onClick={()=>navigate("/")}/>}
                </Box>
                <Box sx={{ display: "inline-flex", height: "100%", width: "100%", flexDirection: "column", justifyContent:"center", alignItems: "center", 
                // boxShadow: "0px 0px 80px 0px rgba(0, 0, 0, 0.10)" 
                }}>
                    <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
                        <Box sx={{ display: "flex", width: "100%", maxWidth: "603px", padding: small?"0px 16px 24px 16px":"32px", flexDirection: "column", alignItems: "center", gap: small?"24px":"56px", background: "var(--pallate-blue-gray-700, #334155)" }}>
                            <Stack rowGap="32px">

                                {!small && <Typography sx={{ height: "32px", color: "var(--pallate-blue-gray-50, #F8FAFC)", textAlign: "center", fontFamily: "inter", fontSize: "30px", fontStyle: "normal", fontWeight: "700", lineHeight: "36px" }}>Reset Password?</Typography>}
                                { message && <Box sx={{background: "var(--pallate-green-200, #BBF7D0)", display:"flex", padding:"8px", justifyContent:"center", alignItems:"center", gap:"8px", alignSelf:"stretch"}}>
                                <Typography sx={{color: "var(--pallate-green-800, #166534)", textAlign:"center", fontFamily:"inter", fontSize:"18px", fontStyle:"normal", fontWeight:"400", lineHeight:"28px"}}>{message}</Typography>
                                </Box>}
                                {error && !small && <Box sx={{background: "var(--pallate-red-200, #FECACA)", display:"flex", padding:"8px", justifyContent:"center", alignItems:"center", gap:"8px", alignSelf:"stretch"}}>
                                <Typography sx={{color: "var(--pallate-red-800, #991B1B)", textAlign:"center", fontFamily:"inter", fontSize:"18px", fontStyle:"normal", fontWeight:"400", lineHeight:"28px"}}>{error}</Typography>
                                </Box>}
                                <Typography sx={{ fontFamily: "inter", fontSize: "18px", fontStyle: "normal", fontWeight: "400", lineHeight: "28px", color: "var(--pallate-blue-gray-200, #E2E8F0)", textAlign: "center" }}>
                                Enter a New Password below to change your current password.
                                </Typography>
                            </Stack>
                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: small?"32px":"48px", alignSelf: "stretch" }}>
                                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-star", gap: "16px", alignSelf: "stretch" }}>
                                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "8px", alignSelf: "stratch", width: "100%" }}>
                                        <Typography sx={{ fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", color: "var(--pallate-blue-gray-50, #F8FAFC)", textAlign: "left" }}>
                                            New Password
                                        </Typography>
                                        <Box sx={{ display: "flex", justifyContent: "space-between", padding: "12px", alignItems: "center", gap: "8px", alignSelf: "stretch", borderRadius: "4px", border: "1px solid var(--light-forms-border, #CBD5E1)", background: "var(--light-forms-background, #FFF)" }}>
                                            <Box sx={{ display: "flex", alignItems: "center", gap: "8px", width: "100%" }}>
                                                <img src={lock} alt="lock" />
                                                <input type={showPassword?"text":"password"} placeholder="Enter Your New Password" style={{ color: "var(--light-text-secondary-text, #64748B)", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", border: "none", outline: "none", width: "100%" }} value={password} onKeyDown={(e)=>handleKeyPress(e)} onChange={(event)=>setPassword(event.target.value)} />
                                                <button style={{ border: "none", backgroundColor: "transparent", cursor: "pointer" }} onClick={(e) => handlePasswordHide(e)}> {!showPassword?<img src={eyeSlash} style={{ display: "flex" }} alt="eye" />:<img src={eye}  style={{ display: "flex" }}  alt="eye-icon" />}</button>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "8px", alignSelf: "stratch", width: "100%" }}>
                                        <Typography sx={{ fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", color: "var(--pallate-blue-gray-50, #F8FAFC)", textAlign: "left" }}>
                                            Confirm Password
                                        </Typography>
                                        <Box sx={{ display: "flex", justifyContent: "space-between", padding: "12px", alignItems: "center", gap: "8px", alignSelf: "stretch", borderRadius: "4px", border: "1px solid var(--light-forms-border, #CBD5E1)", background: "var(--light-forms-background, #FFF)" }}>
                                            <Box sx={{ display: "flex", alignItems: "center", gap: "8px", width: "100%" }}>
                                                <img src={tickIcon} alt="lock" />
                                                <input placeholder="Reconfirm Password" style={{ color: "var(--light-text-secondary-text, #64748B)", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", border: "none", outline: "none", width: "100%" }} value={repassword} onKeyDown={(e)=>handleKeyPress(e)} onChange={(event)=>setRepassword(event.target.value)}/>
                                        </Box>
                                        
                                        </Box>
                                        {small && error && 
                            <Typography sx={{color: "var(--light-text-danger-text, #EF4444)",  fontFamily:"inter", fontSize:"12px", fontStyle:"normal", fontWeight:"400", lineHeight:"16px"}}>{error}</Typography>
                            }
                                    </Box>
                                   {/* {<p style={{ display:"flex", alignItems:"center", justifyContent:"center", color: "#00C2FF", fontFamily: "inter", fontSize: "30px", fontStyle: "notmal", fontWeight: "400", lineHeight: "24px", '&:hover': { cursor: "pointer" } }}>{error}</p> */}
                                {/* } */}
                                 </Box>
                                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "16px", alignSelf: "stretch" }}>
                                    <button disabled={password && repassword ?false:true} type="submit"  style={{ display: "flex", flexDirection: "row", color: " #FFF", fontFamily: "inter", fontSize: small?"14px":"16px", fontStyle: "normal", fontWeight: "400", cursor: password && repassword?"pointer":"not-allowed",lineHeight: "24px", background:password && repassword? "#9746FF":"#475569", padding: small?"8px 12px":"12px", justifyContent: "center", alignItems: "center", alignSelf: "stretch", borderRadius: "4px", border: 'none', cursor: "pointer" }}onClick={(event) => handleSubmit(event)}>Change Password
                                        {/* <img src={login} style={{ display: "flex", marginLeft: "8px", width: "20px", height: "24px", padding: "3.278px 3.25px 3.248px 3.25px", justifyContent: "center", alignItems: "center" }} alt="login"/> */}
                                    </button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
                        <Box sx={{ display: "flex", width: "100%", maxWidth:small?"100%": "603px", padding: small?"20px":"32px", justifyContent: "center", alignItems: "center", gap: "8px", alignSelf: "stretch", background: "var(--pallate-blue-gray-900, #0F172A)" }}>
                            <Typography sx={{ color: "var(--pallate-blue-gray-200, #E2E8F0)", fontFamily: "inter", fontSize: "16px", fontStyle: "notmal", fontWeight: "400", lineHeight: "24px" }}>Back to</Typography>
                            <Typography onClick={() => navigate('/login')} sx={{ color: "#00C2FF", fontFamily: "inter", fontSize: "16px", fontStyle: "notmal", fontWeight: "400", lineHeight: "24px", cursor: "pointer" }}>Login</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}