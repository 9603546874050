import { Box, CircularProgress, Typography, useMediaQuery } from "@mui/material";
import "../styles/update-password.css"
import user from "../assets/icons/🦆 icon _check circle_.svg"
import { useNavigate } from "react-router-dom";

export function UpdatePassword() {
    const navigate = useNavigate()

return(
    <Box className="updatePasssword">
        <Box className="updateptext">
            <Box className="updated">
                <Typography sx={{color:"#FFF", textAlign:"center", fontFamily:"inter", fontSize:"20px", fontStyle:"normal", fontWeight:"700", lineHeight:"28px"}}>PASSWORD UPDATED</Typography>
                <img src={user} style={{width:"115px", height:"115px"}}></img>
                <Typography sx={{color:"var(--pallate-base-white, #FFF)", width:"216px", textAlign:"center", fontFamily:"inter", fontSize:"18px", fontStyle:"normal", fontWeight:"400", lineHeight:"28px"}}>Your password has been updated successfuly</Typography>                
            </Box>
            <Box className="backlogin">
            <Typography sx={{ color: "var(--pallate-base-white, #FFF)", fontFamily: "inter", fontSize: "16px", fontStyle: "notmal", fontWeight: "400", lineHeight: "24px" }}>Back to</Typography>
            <Typography onClick={() => navigate('/login')} sx={{ color: "var(--pallate-base-white, #FFF)", fontFamily: "inter", fontSize: "16px", fontStyle: "notmal", fontWeight: "700", lineHeight: "24px", cursor: "pointer" }}>Login</Typography>                
            </Box>
        </Box>
    </Box>
)
}