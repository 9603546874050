import { Box,Button,Typography, MenuItem,Menu, useMediaQuery } from "@mui/material";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logoutSvg from "../assets/chat/logout.svg"
import privacypolicy from "../assets/dashboard/star-square_svgrepo.com.svg"
import threeDotIcon from "../assets/create-project/ellipses-v (1).svg"
import "../styles/Dashboard.css";
import axios from "axios";

export function MenuThreedot(props) {
    const [Optionsopen, setOptionsopen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const username = JSON.parse(window.sessionStorage.getItem('userdetails')).username
    const credits = props.credits
    const creditsColor = props.creditsColor
    const save_url = props.save_url
    const savedurl = props.url
    


    // const mobile = useMediaQuery({ maxWidth: 600 })
    const navigate = useNavigate()
    const handleOptionsClick = (event) => {
        setAnchorEl(event.currentTarget);
        let dummy_optionOpen = true
        // console.log("in open option",dummy_optionOpen[p_i][f_i])
        setOptionsopen(dummy_optionOpen)
    };
    const handleOptionsClose = () => {
        let dummy_optionOpen = false
        // console.log("in close option",dummy_optionOpen[p_i][f_i])
        setOptionsopen(dummy_optionOpen)
        // setAnchorEl(null);
    };

    function logout(){
		// sessionStorage.removeItem("token")
        sessionStorage.removeItem("userdetails")
		navigate("/login")
	}

    async function redirectURL(){
        console.log(username)
        const {data}= await axios.get(savedurl+"dnaviz/payment_uid/"+username)
        console.log(data)
        //const url = `http://localhost:3001/payment/?uid=${data.unique_id}`;
        const url = `https://stage.accounts.arivu-iq.com/payment/?uid=${data.unique_id}`;
        //const url = `https://stageaiqpayment.nlugpt.io/payment/`
        //localStorage.setItem('sharedValue', username)
        //const data = { username: username }
        //const url = `http://localhost:3001/payment/`;
        //window.parent.postMessage(data, url);
        window.location.href = url;
        //window.open(`https://stageaiqpayment.nlugpt.io/payment/?username=${username}`);
        //window.open(`http://localhost:3001/payment/?username=${username}`, "_blank");
    }
    function redirectURLHelp(){
        const url = `https://arivu-iq.com/privacy-policy/`;
        window.open(url,'_blank');
    }

    return (
        <>
<Box sx={{display:"flex",width:"100%", justifyContent:"flex-end"}}>
{/* <Box className="credits_info">
                    <Typography sx={{ fontFamily: "inter", fontSize: "20px", fontStyle: "normal",marginTop:"36px", fontWeight: "700", lineHeight: "24px", color: "#FFF" ,overflowX:"hidden",background: "linear-gradient(115deg, #00C2FF 31.83%, #9746FF 84.85%)", backgroundClip: "text", color: "transparent"}}>Credits Left:</Typography>
                    <Typography sx={{ fontFamily: "inter", fontSize: "20px", fontStyle: "normal",marginTop:"36px", fontWeight: "700", lineHeight: "24px", color: "#FFF" ,overflowX:"hidden",background: creditsColor, backgroundClip: "text", color: "transparent"}}>{credits}</Typography>
                    </Box> */}
           
                <button
                    className="threedot-mob"
                    id="file-option-button"
                    aria-controls={Optionsopen ? 'file-option-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={Optionsopen}
                    onClick={(e) => { e.stopPropagation(); handleOptionsClick(e) }}

                >
                 <img className="threedots" src={threeDotIcon} alt="threedots" />
                </button>
                

                <Menu
                    id="file-option-menu"
                    anchorEl={anchorEl}
                    open={Optionsopen}
                    // open={true}
                    onClose={() => { handleOptionsClose() }}
                    sx={{
                        ".MuiMenu-paper": {
                            backgroundColor: "var(--pallate-blue-gray-700, #334155)"
                        }
                    }}
                    MenuListProps={{
                        'aria-labelledby': 'file-option-button',
                        'style': {
                            color: "var(--light-text-inverse-text, #FFF)",
                            borderRadius: "4px",
                            width: "210px", padding: "0"
                        }
                    }}
                >
                    <MenuItem sx={{   display: "flex", alignItems: "center",  padding: "8px 15px" }} onClick={() => redirectURL()}>Account</MenuItem>
                    {/* <MenuItem sx={{   display: "flex", alignItems: "center", borderTop: "1px solid grey", padding: "8px 15px" }} onClick={() => redirectURLHelp()}><Typography sx={{width:"100%"}}>Help</Typography><Box component="img" src={privacypolicy} sx={{width:"20px",height:"20px"}}/></MenuItem> */}
                    <MenuItem sx={{padding:"8px 15px",display:"flex",alignItems:"center",borderTop: "1px solid grey",bgcolor:"#9746FF"}} onClick={logout}><Typography sx={{width:"100%"}}>Logout</Typography><Box component="img" src={logoutSvg} sx={{width:"20px",height:"20px"}}/></MenuItem>


                </Menu>
                </Box>
           
        </>
    )
}