import { Typography } from "@mui/material";
import "../styles/loading.css"

export function Loading() {
    return (
        <div className="Loading-container">
            <div className="loader">
                <div className="loading-box">
                    <div className="loading">
                        <div className="loaded">
                        </div>
                    </div>
                </div>
                <Typography sx={{ color: "#FFF", textAlign: "center", fontFamily: "inter", fontSize: "24px", fontStyle: "normal", fontWeight: "600", lineHeight: "32px" }}>Please wait, Your data is getting ready</Typography>
            </div>
        </div>
    )
}