import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const CheckoutForm = (props) => {
const navigate = useNavigate()
const api_base_url = props.api_base_url
const save_url= props.savedata
const [result, setResult]=useState('')
const [username,setUsername]=useState('')
  const stripe = useStripe();
  const elements = useElements();
  const user_details = JSON.parse(window.sessionStorage.getItem('userdetails'))
  
  useEffect(()=>{
    let user_details = JSON.parse(window.sessionStorage.getItem('userdetails'))
    console.log(user_details)
    if(user_details){
        setUsername(user_details.username)
    }
    else{
        navigate("/login")
    }
   
},[])
  
  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
    
      elements,
      confirmParams: {
        return_url: "https://dashboard.d26glbultgwjpw.amplifyapp.com/payment_sucess",
        //return_url: "http://localhost:3000/payment_sucess",
      },
      
    });
    setResult(result)
    console.log(result)

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button disabled={!stripe}>Submit</button>
    </form>
  )
};

export default CheckoutForm;