import { Box, CircularProgress, Typography } from "@mui/material";
import "../styles/payment.css"
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from "./checkoutForm";
import arrowLeft from "../assets/chat/arrow-left (1).svg";

export function Payment(props) {
    const navigate = useNavigate()
    const api_base_url = props.savedata
    const save_url= props.api_base_url
    const stripePromise = loadStripe('pk_test_51LJQvQGbxvISa8ejCyLiIAZ19LOyCeufUM2kxWM9aZWaHsYVl8neXDHYPnLG3tqRpydmxxW7WRb5Q0OyKfaGiDBl00wbiU1HMT');
    const [data,setData]=useState({})
    const [stripedata,setStripeData]=useState({})
    const user_details = JSON.parse(window.sessionStorage.getItem('userdetails'))
    const [paymentTemplate,setPaymentTemplate]=useState(false)
    const [username,setUsername]=useState('')
    const [tier,setTier]=useState('free plan')
   
    useEffect(()=>{
        let user_details = JSON.parse(window.sessionStorage.getItem('userdetails'))
        console.log(user_details)
        if(user_details){
            setUsername(user_details.username)
        }
        else{
            navigate("/login")
        }
       
                  
        console.log(data)
        console.log(user_details.username)
       
    },[])
    useEffect(()=>{
     async function fetchData() {
        const {data}=await axios.post(save_url+'dnaviz/payment_info/',{
            "username":user_details.username,
            })
        setData(data)
        if (data.user_tier==="paid plan"){
        setTier(data.user_tier)
        } 
        }
             
            fetchData();
            
    },[])
    let options
    const getClientSecret =async()=>{
        const {data}=await axios.post(save_url+'dnaviz/payment_update/',{
            "username":user_details.username,
            })
        console.log(data.client_secret)
        setStripeData(data.client_secret)
        
        //   console.log(options)
        setPaymentTemplate(true)
    }
    
    // useEffect(()=>{
    //     console.log(stripedata)
    //     options = {
    //         // passing the client secret obtained from the server
    
    //         clientSecret: stripedata.client_secret
    //       };
    // },[stripedata])
    const Payment_Gateway=async()=>{
        getClientSecret()
        
    }


    return(<>
        {paymentTemplate?<Elements stripe={stripePromise} options={{clientSecret:stripedata}}>
      <CheckoutForm savedata={save_url}/>
    </Elements>:<Box>
        <Box className="payment-container">
        <Box className="button-container1">
                    <button className="back-button1" onClick={()=>navigate('/dashboard')}> <img src={arrowLeft} className="arrow1" alt="arrow-icon" /></button>
                    
                </Box>
            <Box className="payment-info">
                <Typography sx={{fontSize:"40px", fontWeight:"500", textTransform:"capitalize", color:"var(--pallate-blue-gray-50, #F8FAFC)"}}>
                    Payment Information
                </Typography>
            <Box className="payment-box">
                <Typography sx={{paddingLeft:"20px",fontSize:"30px", fontWeight:"500", textTransform:"capitalize", color:"var(--pallate-blue-gray-50, #F8FAFC)"}}>
                    Billing & Payments
                </Typography>
                <Box className="user-tier">
                <Typography sx={{paddingLeft:"20px",fontSize:"20px", fontWeight:"400", textTransform:"capitalize", color:"var(--pallate-blue-gray-50, #F8FAFC)"}}>
                    Your current Plan: {tier}
                </Typography>
                <Typography sx={{paddingLeft:"20px",fontSize:"20px", fontWeight:"400", textTransform:"capitalize", color:"var(--pallate-blue-gray-50, #F8FAFC)"}}>
                    Your credit balance: {data.credits_left}
                </Typography>
                </Box>
                <hr className="hrLine" />
                <Typography sx={{paddingLeft:"20px",fontSize:"30px", fontWeight:"500", textTransform:"capitalize", color:"var(--pallate-blue-gray-50, #F8FAFC)"}}>
                    Pricing Plans
                </Typography>
                <Box className="plan_details">
                <Box className="paid_plan">
                <Typography sx={{fontSize:"20px", fontWeight:"400", textTransform:"capitalize", color:"#FFF"}}>
                    Paid Plan Details
                </Typography>
                <Typography sx={{fontSize:"40px", fontWeight:"400", textTransform:"capitalize", color:"#FFF"}}>
                    Pay 5$ and get 500 credits
                </Typography>
                <button style={{ border:"none",borderRadius:"10px",display: "flex", padding: "12px", justifyContent: "center", alignItems: "center", gap: "8px", borderRadius: "4px", background: "var(--pallate-blue-gray-900, #0F172A)",cursor:"pointer" }} onClick={Payment_Gateway}>
                            <Typography sx={{ color: "var(--pallate-base-white, #FFF)", fontFamily: "inter", fontSize: "18px", fontStyle: "normal", fontWeight: "600", lineHeight: "28px" }}>Buy Now</Typography>
                </button>
                </Box>
                <Box className="custom_plan">
                <Typography sx={{fontSize:"20px", fontWeight:"400", textTransform:"capitalize", color:"#FFF"}}>
                    Custom Plan 
                </Typography>
                <Typography sx={{fontSize:"20px", fontWeight:"400", textTransform:"capitalize", color:"#FFF"}}>
                 Please provide your details and we will contact you back on the pricing details. Thank you for showing you interest in our product
                 </Typography> 
                 <button style={{border:"none",borderRadius:"10px", color:"white",display: "flex", padding: "12px", justifyContent: "center", alignItems: "center", gap: "8px", borderRadius: "4px", background: "var(--pallate-blue-gray-900, #0F172A)",cursor:"pointer" }} >
                 <Typography sx={{ color: "var(--pallate-base-white, #FFF)", fontFamily: "inter", fontSize: "18px", fontStyle: "normal", fontWeight: "600", lineHeight: "28px" }}>Contact us  </Typography> </button>
                </Box>
                </Box>

            </Box>
            </Box>
        </Box></Box>}</>

    )
}