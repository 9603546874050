import { Box, InputAdornment, Stack, TextField, Typography, useMediaQuery} from "@mui/material";
import "../styles/forgotPassword.css"
import forgotPasssword from "../assets/icons/forgot.svg"
import arrowLeft from "../assets/create-project/arrow-left.svg"
import frame from "../assets/landing/D&AViz_Without tagline-Color-Light Lite.svg"
import { useNavigate } from "react-router-dom";
import { useState } from "react"
import axios from "axios"
import forgotbg from "../assets/icons/forgot-bg.svg"
import user from "../assets/icons/user.svg"
import { PermIdentity } from "@mui/icons-material";


export function ForgotPassword(props) {
    const navigate = useNavigate()
    const api_base_url = props.api_base_url
    const [email,setEmail] = useState('')
    const [error,setError] = useState(null)
    const [message, setMessage] = useState('')
    const small = useMediaQuery("(max-width: 600px)");
    const tab =  useMediaQuery('(min-width: 600px) and (max-width: 900px)');

    const handleSubmit = (event)=>{
        event.preventDefault()
        const regex=/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        if(!email){
            setError("Email id is required*")
            setMessage('')
        }
        else if(!regex.test(email)){
            setError("Enter Valid Mail Id")
        }
        else{
            sendRequest()
            setError('')
            setEmail('')   
            // // console.log("email id--",email)
        }
        
    }

    const sendRequest=async()=>{
        // // console.log("email addres",email)
        const {data}=await axios.post( api_base_url + 'account/forgot_password/',
        {
            username:email
        })
        console.log(data)
        if (data.error){
            setError('Unable to find the email id')
            setMessage('') 
            }
            else{
            setEmail('')
            setMessage('We have emailed your password reset link')
            setError('')
            
            }

    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
          handleSubmit(e); 
        }
      };
    return (
        <Box className="forgotPasssword" sx={small&&{bgcolor:"#334155"}}>
            <Box sx={{ display: "flex", flexDirection: "column", width: "100%",height:small?"60vh":"100vh" }}>
                {!small &&<Box className="button-container">
                    <button className="back-button" onClick={() => { navigate(-1) }}> <img src={arrowLeft} className="arrow" alt="arrow-icon" /></button>
                </Box>}
                <Box className="Rectangleframe-forgotpassword" sx={small&&{justifyContent:"center",padding:"0px",backgroundImage:`url(${forgotbg})`,mb:"44px",backgroundPosition:"bottom",backgroundRepeat:"no-repeat",backgroundSize:"cover"}}>
                <Box sx={{display:"flex", alignItems:"center",mb:"16px" }}>
                <Typography sx={{ color: small ? "#F8FAFC" : "var(--pallate-gray-300, #D1D5DB)",width:"100%", maxWidth: small ? "320px" : "477px", textAlign: "center", fontFamily: "inter", fontSize: small ? "20px" : "48px", fontStyle: "normal", fontWeight: "400", lineHeight: small ? "28px" : "57px"}}>
                        <span style={{ display: small ? "block" : "unset", textAlign:"center", alignItems:"center", color: "var(--pallate-blue-gray-50, #F8FAFC)", marginRight: "20px", fontFamily: "inter", fontWeight: small?"700":"800" }}>
                            Forgot your Password!
                        </span>
                        No worries, we've got you covered.
                    </Typography>
                    </Box>
                    <img src={forgotPasssword} 
                    style={{ width: small?"100%":"unset", 
                    // maxWidth: small ? "min(189.49px, 188.723px)":"min(90vw, 604px)", 
                    maxWidth:small?"min(189.49px, 188.723px)":"unset",
                    flexShrink: "1" }} 
                    alt="forgotPassword" />
                </Box>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column",  width: "100%", height:{md:"100vh"} }}>
            <Box sx={{height:small? "fit-content":"100vh",width:"100%",display:"flex",flexDirection:"column",justifyContent:"center"}}>
                <Box sx={{ width: "100%", display: "flex", alignItems: "flex-start", justifyContent: "flex-end", marginRight: "10px" }}>
                    {!small&&
                    <img src={frame} style={{ width: "82px", height: "30px", flexShrink: "0", marginTop: "36px", marginRight: "32px",cursor:"pointer"}} alt="frame" onClick={()=>navigate("/")}/>}
                </Box>
                <Box sx={{ display: "inline-flex", height: "100%", width: "100%", flexDirection: "column", justifyContent: "center", alignItems: "center", 
                // boxShadow: "0px 0px 80px 0px rgba(0, 0, 0, 0.10)" 
                }}>
                    <Box sx={{ display: "flex", width:"100%", justifyContent: "center" }}>
                        <Box sx={{ display: "flex", width: "100%", maxWidth: "603px", padding: small?"0px 16px":"32px", flexDirection: "column", alignItems: "center", gap:small?"22px": "56px", background: "var(--pallate-blue-gray-700, #334155)" }}>
                            <Stack rowGap="32px">
                                {!small && <Typography sx={{ height: "32px", color: "var(--pallate-blue-gray-50, #F8FAFC)", textAlign: "center", fontFamily: "inter", fontSize: "30px", fontStyle: "normal", fontWeight: "700", lineHeight: "36px" }}>Forgot Password?</Typography>}
                                {message && <Box sx={{background: "var(--pallate-green-200, #BBF7D0)", display:"flex", padding:"8px", justifyContent:"center", alignItems:"center", gap:"8px", alignSelf:"stretch"}}>
                                <Typography sx={{color: "var(--pallate-green-800, #166534)", textAlign:"center", fontFamily:"inter", fontSize:"18px", fontStyle:"normal", fontWeight:"400", lineHeight:"28px"}}>{message}</Typography>
                                </Box>}
                                {!small && error && <Box sx={{background: "var(--pallate-red-200, #FECACA)", display:"flex", padding:"8px", justifyContent:"center", alignItems:"center", gap:"8px", alignSelf:"stretch"}}>
                                <Typography sx={{color: "var(--pallate-red-800, #991B1B)", textAlign:"center", fontFamily:"inter", fontSize:"18px", fontStyle:"normal", fontWeight:"400", lineHeight:"28px"}}>{error}</Typography>
                                </Box>}
                                <Typography sx={{ mb:"22px",mt:"44px", fontFamily: "inter", fontSize: "18px", fontStyle: "normal", fontWeight: "400", lineHeight: "28px", color: "var(--pallate-blue-gray-200, #E2E8F0)", textAlign: "center" }}>
                                Please enter the email address you’d like your password reset information sent to:
                                </Typography>
                            </Stack>
                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: small?"32px":"48px", alignSelf: "stretch",mb:small?"43px":"unset" }}>
                                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-star", gap: "24px", alignSelf: "stretch" }}>
                                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "8px", alignSelf: "stratch", width: "100%" }}>
                                        <Typography sx={{ fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", color: "var(--pallate-blue-gray-50, #F8FAFC)", textAlign: "left" }}>
                                        Enter email address <Typography component="span" sx={{color:"var(--light-text-danger-text, #EF4444)", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px"}}>*</Typography>
                                        </Typography>
                                        <Box sx={{ display: "flex", padding: "12px", alignItems: "center", gap: "8px", alignSelf: "stretch", borderRadius: "4px", border: "1px solid var(--light-forms-border, #CBD5E1)", background: "var(--light-forms-background, #FFF)" }}>
                                                    <img src={user} alt="user" style={{padding:"4.75px 4.25px",height:"24",width:"24px",display:"flex",justifyContent:"center",alignItems:"center"}}/>
                                                    <input id="username" name="username" onKeyDown={(e)=>handleKeyPress(e)} onChange={(event)=>setEmail(event.target.value)} value={email}  placeholder="Your email address" style={{ color: "var(--light-text-secondary-text, #64748B)", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", border: "none", outline: "none", width: "100%" }} />
                                                </Box>
                                        {/* <Box sx={{ display: "flex",alignItems: "center", gap: "8px", alignSelf: "stretch"}}> */}
                                            {/* <img src={user} alt="user"/> */}
                                            {/* <TextField
                                                placeholder="Your email address"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <PermIdentity/>
                                                        </InputAdornment>
                                                    ),
                                                    style:{
                                                        height:"24px",
                                                        maxWidth:"304px",
                                                        width:"100%",
                                                        paddingLeft:"0px",
                                                        border:"none"
                                                    }
                                                }}
                                                sx={{".MuiOutlinedInput-notchedOutline":{border:"none"},p:"12px",borderRadius: "4px",background: "var(--light-forms-background, #FFF)",color: "var(--light-text-secondary-text, #64748B)", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", border: "none", outline: "none", width: "100%" }} value={email} onKeyDown={(e)=>handleKeyPress(e)} onChange={(event)=>setEmail(event.target.value)}
                                            />
                                        </Box> */}
                                            {/* <input placeholder="Enter your user name" style={{ color: "var(--light-text-secondary-text, #64748B)", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", border: "none", outline: "none", width: "100%" }} value={email} onKeyDown={(e)=>handleKeyPress(e)} onChange={(event)=>setEmail(event.target.value)}/> */}
                                        {small && error && 
                            <Typography sx={{color: "var(--light-text-danger-text, #EF4444)",  fontFamily:"inter", fontSize:"12px", fontStyle:"normal", fontWeight:"400", lineHeight:"16px"}}>{error}</Typography>
                            }
                                    </Box>
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "16px", alignSelf: "stretch" }}>
                                    <button disabled={email ?false:true} type="submit" style={{ display: "flex", flexDirection: "row", color: " #FFF", fontFamily: "inter", fontSize: "14px", fontStyle: "normal", fontWeight: "400", cursor: email?"pointer":"not-allowed",lineHeight: "24px", background:email? "#9746FF":"#475569", padding: "18px 2px", justifyContent: "center", alignItems: "center", alignSelf: "stretch", borderRadius: "4px", border: 'none', cursor: "pointer" }} onClick={(event)=>handleSubmit(event)}>Send Link
                                        {/* <img src={login} style={{ display: "flex", marginLeft: "8px", width: "20px", height: "24px", padding: "3.278px 3.25px 3.248px 3.25px", justifyContent: "center", alignItems: "center" }} alt="login"/> */}
                                    </button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
                        <Box sx={{ display: "flex", width: "100%", maxWidth:small?"100%": "603px", padding: small?"20px":"32px", justifyContent: "center", alignItems: "center", gap: "8px", alignSelf: "stretch", background: "var(--pallate-blue-gray-900, #0F172A)" }}>
                            <Typography sx={{ color: "var(--pallate-blue-gray-200, #E2E8F0)", fontFamily: "inter", fontSize: "16px", fontStyle: "notmal", fontWeight: "400", lineHeight: "24px" }}>Back to</Typography>
                            <Typography onClick={() => { navigate('/login') }} sx={{ color: "#00C2FF", fontFamily: "inter", fontSize: "16px", fontStyle: "notmal", fontWeight: "400", lineHeight: "24px", '&:hover': { cursor: "pointer" } }}>Login</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            </Box>
            </Box>
    )
}