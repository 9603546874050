import React, { useEffect, useState } from "react";
import { Box,useMediaQuery, Typography, Grid} from "@mui/material"
import { Bar, Line, Pie, Doughnut, PolarArea, Radar, Scatter, Bubble } from 'react-chartjs-2';
import { Paper, TableContainer, Table, TableBody } from "@mui/material";
import loader from "../assets/chat/12 Hourglass.gif"




const ChatMessage = ({index, message,loadtext }) => {
    const mainKey = []
	const keys = []
    const values = []
    const tab =  useMediaQuery('(min-width: 600px) and (max-width: 900px)');
    const mob =  useMediaQuery('(max-width: 600px)');
	const response = message.response;
	let data
	if(message.response.viz === "dnaTable"){
		if(response.stacked===true){
			// for(const key in response.dataValues){ 
			// 	keys.push(key)
			// 	// values.push(response.dataValues[key])	
			// }
			response.dataValues.map((val,i)=>keys.push(Object.keys(val)))
			response.dataValues.map((val, i) => values.push(Object.values(response.dataValues[i])))
			response.labels.map((key) => mainKey.push(key))
		} else {

			response.labels.map((key) => keys.push(key))
			response.dataValues.map((value) => values.push(value))
		}
		
}
	const tableValues = [keys, values]
	let colors = ["#00C2FF", "#9746FF", "#83FF21", "#FDE119", "#2DEEA8", "#FF6666"]

	if ( message.response.stacked === true ) {

		const datasets2 = Object.keys(message.response.dataValues1).map((key, index) => {
			const backgroundColor = index % 2 === 0 ? colors : colors;

			return {
				label: key.charAt(0).toUpperCase() + key.slice(1), // Capitalize the first letter
				data: message.response.dataValues1[key],
				backgroundColor: [...colors, ...["#00C2FF", "#9746FF", "#83FF21", "#FDE119", "#2DEEA8", "#FF6666"]],
				borderWidth: 1,
				borderColor: message.response.viz === "lineChart"?"#FFF":"transparent",
				barPercentage: 0.7,
				categoryPercentage: 0.9,
				borderColor: [...colors, ...["#00C2FF", "#9746FF", "#83FF21", "#FDE119", "#2DEEA8", "#FF6666"]],
				padding: 10,
			};
		});

		data = {
			labels: message.response.labels.map((label)=>label = label.charAt(0).toUpperCase() + label.slice(1)),
			datasets: datasets2
		};

	}
	else {
		data = {
			labels: message.response.labels,
			datasets: [
				{
					data: message.response.dataValues,
					backgroundColor: [...colors, ...["#00C2FF", "#9746FF", "#83FF21", "#FDE119", "#2DEEA8", "#FF6666"]],
					borderColor: message.response.viz === "lineChart"?"#FFF":"transparent",
					borderWidth: 1,
					barThickness: 20,
					barPercentage: 0.7,
					categoryPercentage: 0.9,
					padding: 10,
				}
			]
		};
	}

	// console.log("================================", datasets2)
	const chartOptions = {
		aspectRatio: 1.5,
		scales: {
			x: {
				beginAtZero: true,
				display:message.response.viz === "pieChart" || message.response.viz === "doughnutChart" || message.response.viz === "polarareaChart" ? false : true,
				ticks: {
					color: 'white',
					display: true
				},
				grid: {
					color: "white",
					display: false
				}
			},
			y: {
				beginAtZero: true,
				display:message.response.viz === "pieChart" || message.response.viz === "doughnutChart" || message.response.viz === "polarareaChart" ? false : true,
				title:{
					text:"Values -->",
					display:true,
					color:"white",
					fontSize:20
				},
				ticks: {
					color: 'white',
					display: message.response.viz === "pieChart" || message.response.viz === "doughnutChart" || message.response.viz === "polarareaChart" ? false : true
				},
				grid: {
					color: "white",
					display: false
				}
			},
		},
		onClick: (event, elements) => {
			if (elements.length > 0) {
				const datasetIndex = elements[0].datasetIndex;
				const dataIndex = elements[0].index;
				const dataValue = event.chart.data.datasets[datasetIndex].data[dataIndex];
			}
		},
		responsive: true,
		maintainAspectRatio: true,
		plugins: {
			legend: {
				onClick: (e, legendItem, legend) => {
					const chart = legend.chart;
					const datasetIndex = chart.data.labels.indexOf(legendItem.text)
					chart.toggleDataVisibility(datasetIndex)
					chart.update();
				},
				display: true,
				position: 'bottom',
				labels: {
					color: '#fff',
					usePointStyle: true,
					pointStyle: 'circle',
					boxWidth: 6,
					boxHeight: 6,
					boxRadius: 5,
					padding: 18, 
					generateLabels: (chart) => {
						let visibility = []
						for (let i = 0; i < chart.data.labels.length; i++) {
							if (chart.getDataVisibility(i) === true) {
								visibility.push(false)
							} else {
								visibility.push(true)
							}
						}
						const originalLabels = chart.data.labels;
						
						const labels = [];

						originalLabels.forEach((label, index) => {
							labels.push({
								// text:!message.response.stacked?label.charAt(0).toUpperCase()+label.slice(1)+" "+"("+response.dataValues1[0][index]+", "+response.dataValues1[1][index]+")":label.charAt(0).toUpperCase()+label.slice(1)+" "+"("+response.dataValues1[0][index]+")",
								text:label.charAt(0).toUpperCase() + label.slice(1),
								fillStyle: colors[index],
								hidden: visibility[index],
								fontColor: "white",
								categoryPercentage: 1,
								barPercentage: 1,
								
							});
						});
						if(labels.length>=5){
							for(let i=0;i<labels.length;i++){
						colors = [...colors,...["#00C2FF", "#9746FF", "#83FF21", "#FDE119", "#2DEEA8", "#FF6666"]];							
							
					}
						  }
						return labels;
					},
				},
			},
			title: {
				display: true,
				text: 'Visualization',
				color: 'white',
			},
		},
	};
	const value = "section_" + index
	const [randomText, setRandomText] = useState(loadtext[(Math.floor(Math.random() * loadtext.length))]);

	useEffect(() => {
	  const intervalId = setInterval(() => {
		const randomIndex = Math.floor(Math.random() * loadtext.length);
		setRandomText(loadtext[randomIndex]);
	  }, 9000);
  
	  // Clear the interval when the component is unmounted
	  return () => clearInterval(intervalId);
	}, [loadtext]);
	
	
	//////////////////////////////////////////////////////////////// REFERENECE FOR STACKED BAR CHARTS //////////////////////////////////////////////////////////////////
	//   const chartData1 = {
	// 	labels: ["India", "China", "Malaysia"],
	// 	datasets: [
	// 	  {
	// 		label: 'Male',
	// 		backgroundColor: 'rgba(54, 162, 235, 0.6)', // Blue
	// 		borderColor: 'rgb(54, 162, 235)',
	// 		borderWidth: 1,
	// 		data: [200, 180, 150]
	// 	  },
	// 	  {
	// 		label: 'Female',
	// 		backgroundColor: 'rgba(255, 99, 132, 0.6)', // Pink
	// 		borderColor: 'rgb(255, 99, 132)',
	// 		borderWidth: 1,
	// 		data: [132, 120, 110]
	// 	  }
	// 	]
	//   };

	//   const chartOptions1 = {
	// 	scales: {
	// 	  x: { stacked: true },
	// 	  y: { stacked: true }
	// 	}
	//   };
	
	let question = message.message.charAt(0).toUpperCase() + message.message.slice(1)
	// .split(' ').map((words)=>{
	// 	questions.push(words)
	// })
	// let question = questions.join(" ")
	return (
		// <Grid id={value} container p={4}>
        <Grid id={value} sx={{width:tab?"98%":"100%"}}>
			<Box>
				{!mob?<><Typography sx={{fontSize:tab?"20px":"24px", fontFamily: "inter", fontStyle: "normal", fontWeight: "700", lineHeight: "36px", color: "#FFF", textAlign: "left", wordBreak:"break-word",maxWidth:mob?"350px":"650px",width:"100%" }}>{question}</Typography>
				{message.response=='' && <Box sx={{width:"100%",display:"flex",justifyContent:"flex-start"}}>
                    <img src={loader} style={{width:"70px",height:"70px",marginTop:"20px"}}/>
                    <Typography sx={{marginTop:"20px",display:"flex",alignItems:"center",fontFamily:"inter",fontSize:"14px",fontWeight:700,lineHeight:"20px",color:"white"}}>{randomText}</Typography>             
                </Box>}
				</>:
				<><Box sx={{display:"flex",maxWidth:"292px",padding:"12px",justifyContent:"center",alignItems:"center",gap:"8px",borderRadius:"8px",background:"var(--pallate-blue-gray-600, #475569)"}}>
				<Typography sx={{fontSize:"16px", fontFamily: "inter", fontStyle: "normal", fontWeight: "700", lineHeight: "24px", color: "#FFF", textAlign: "left", wordBreak:"break-word",width:"100%" }}>{question}</Typography>	
				</Box>
				{message.response=='' && <Box sx={{width:"100%",display:"flex",justifyContent:"flex-start"}}>
                    <img src={loader} style={{width:mob?"40px":"70px",height:mob?"40px":"70px",marginTop:"20px"}}/>
                    <Typography sx={{marginTop:"20px",display:"flex",alignItems:"center",fontFamily:"inter",fontSize:mob?"12px":"14px",fontWeight:700,lineHeight:"20px",color:"white"}}>{randomText}</Typography>             
                </Box>}</>}
				{!message.response.viz && 
				<Box sx={{marginTop:"20px",flexShrink:"0",borderRadius:"8px",display:"flex",alignItems:"center"}}>
					<Typography sx={{ fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "120%", color: "#FFF"}}>{message.response.message}</Typography>
				</Box>}
			</Box>
			<Box sx={{ display: "flex", justifyContent: "center",marginTop:"20px" }}>
				{message.response.viz === "dnaTable" && <>
					<Box className="chart-container" sx={{ height: "fit-content", textAlign: "-webkit-center",marginTop:"20px"}} >
						<Paper sx={{ width: "fit-content", textAlign: "-webkit-center" }}>
							<TableContainer sx={{ width: "fit-content" }}>
								<Table >
									<TableBody >
										{response.stacked === true ?
											mainKey.map((m_key, i) => (
												Array.isArray(keys[i])&& keys[i].map((key,key_i)=>(
													<tr key={i+key_i} style={{ borderBottom: "1px solid black" }}>	
														{ key_i === 0 &&<td rowSpan={key_i ===0 ?keys[i].length : 1} style={{ backgroundColor: "#9746FF", textAlign: "center", width: "200px", color: "white", padding: "20px", borderBottom: "1px solid black" }}>{m_key}</td>}
														<td style={{ padding: "20px", width: "200px",borderRight:"1px solid black" }}>{key}</td>
														<td style={{ padding: "20px", width: "200px" }}>{values[i][key_i]}</td>
													</tr>
												))
											))
											:
											<>
												{keys.map((val, index) => (
													<tr key={index} style={{ borderBottom: "1px solid black" }}>
														<td style={{ backgroundColor: "#9746FF", textAlign: "center", width: "200px", color: "white", padding: "20px" }}>{tableValues[0][index]}</td>
														<td style={{ backgroundColor: "#FFF", textAlign: "center", width: "200px", padding: "20px" }}>{tableValues[1][index]}</td>
													</tr>
												))}
											</>}
									</TableBody>
								</Table>
							</TableContainer>

						</Paper>
					</Box>
				</>
				}
				{message.response.viz === "pieChart" && (

					<>
						<div style={{ display: "flex", flexDirection: "column" ,marginTop:"10px",width:"650px"}}>
							<Pie data={data} options={chartOptions} />
							
						</div>
					</>

				)}
				{message.response.viz === "barChart" && (
					<>

						<div style={{ display: "flex", flexDirection: "column",marginTop:"10px" ,width:"650px"}}>
							<Bar data={data} options={chartOptions} />
						</div>
					</>
				)}
				{message.response.viz === "doughnutChart" && (
					<>
						<div style={{ display: "flex", flexDirection: "column",marginTop:"10px",width:"650px" }}>
							<Doughnut data={data} options={chartOptions} />
							
						</div>
					</>
				)}
				{message.response.viz === "lineChart" && (
					<>
						<div style={{ display: "flex", flexDirection: "column",marginTop:"10px",width:"650px" }}>
							<Line data={data} options={chartOptions} />
							</div>

					</>
				)}
				{message.response.viz === "polarareaChart" && (
					<>
						<div style={{ display: "flex", flexDirection: "column",marginTop:"10px",width:"650px" }}>
							<PolarArea data={data} options={chartOptions} />
								</div>
					</>
				)}
				{message.response.viz === "radarChart" && (
					<>
						<Box className="chart-container" sx={{ width: "500px", textAlign: "-webkit-center" }} >
							<Radar data={data} options={chartOptions} />
						</Box>
					</>
				)}
				{message.response.viz === "scatterChart" && (
					<>
						<Box className="chart-container" sx={{ width: "500px", textAlign: "-webkit-center" }} >
							<Scatter data={data} options={chartOptions} />
						</Box>
					</>
				)}
				{message.response.viz === "bubbleChart" && (
					<>
						<Box className="chart-container" sx={{ width: "500px", textAlign: "-webkit-center" }} >
							<Bubble data={data} options={chartOptions} />
						</Box>
					</>
				)}
			</Box>
		</Grid>
		// </Grid>
	);
};


export default ChatMessage